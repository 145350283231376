import React from "react";

const CircularProgressIndicator = ({ color = "white", w = "5", h = "5" }) => {
  return (
    <div
      className={`h-${h} w-${w} border-t-2 border-r-2 border-${color} animate-spin rounded-full`}
    ></div>
  );
};

export default CircularProgressIndicator;
