import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { URLS } from "./constants/route-links";
import Dashboard from "./pages/routes/dashboard/dashboard";
import Home from "./pages/home/home";
import Users from "./pages/routes/users/users";
import ReportDetail from "./pages/routes/reports/report-detail";
import Login from "./pages/authentication/login";

function App() {
  return (
    <Router>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Login />} />
        {/* <Route
          path={URLS.initiatePasswordReset.route}
          element={<InitiatePasswordReset />}
        /> */}
        {/* <Route path="/" element={<ResetPassword />}/> */}
        <Route path={URLS.home.route} element={<Home />}>
          <Route path={URLS.home.route} element={<Dashboard />} />
          <Route path={URLS.dashboard.route} element={<Dashboard />} />
          <Route path={URLS.users.route} element={<Users />} />
          <Route
            path={`${URLS.reportDetail.route}/:type`}
            element={<ReportDetail />}
          />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
