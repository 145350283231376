import { useDispatch } from "react-redux";
import { getAsObjectFromLocalStorage } from "../libraries/easy";
import {getUser} from "../../redux/slices/user-slice";

export const useAuthChecker = () => {
  const dispatch = useDispatch()
  const checkAuth = () => {
    let userObjectIsPresent = getAsObjectFromLocalStorage("lcReportUser");
    if (!!userObjectIsPresent) {
      dispatch(getUser(userObjectIsPresent))
      return true;
    } else {
      return false;
    }
  };
  return { checkAuth };
};
