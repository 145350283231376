import React, { useRef } from "react";
import { getTodaysDate, getTommorowsDate } from "../../libraries/easy";

const ModifiedDateRangePicker = ({
  handleCancel,
  handleSubmit,
  handleStartChange,
  endMinDate,
  defaultStart,
  defaultEnd,
}) => {
  const startRef = useRef(getTodaysDate());
  const endRef = useRef(getTommorowsDate());
  return (
    <div
      style={{
        transform: "scale(0.9)",
        fontWeight: 300,
      }}
      className="h-full sm:w-[205px] bg-white xs:w-full  "
    >
      <h1 className="mb-4  text-gray-900  text-center my-4 text-2xl font-medium">
        Select Date
      </h1>
      <div className="flex items-center font-semibold  p-4 text-md  text-gray-500">
        <div className="w-24">Start Date: </div>
        &nbsp;
        <input
          min="2022-01-01"
          ref={startRef}
          max={getTodaysDate()}
          style={{
            outline: "none !important",

            textAlign: "center",
          }}
          defaultValue={defaultStart || getTodaysDate()}
          onChange={(e) => {
            handleStartChange(e.target.value);
          }}
          className="rounded-md bg-gray-50 border-2 min-w-[180px] px-4 py-2 text-xs sm:text-base text-black font-semibold cursor-pointer outline-none"
          type="date"
        />
      </div>

      <div className="flex items-center font-semibold  p-4 text-md  text-gray-500">
        <div className="w-24">End Date: </div>
        &nbsp;
        <input
          ref={endRef}
          max={getTommorowsDate()}
          min={endMinDate}
          style={{
            outline: "none !important",
            textAlign: "center",
            color: "black",
          }}
          defaultValue={defaultEnd || getTommorowsDate()}
          // onChange={(e) => {
          //   handleEndChange(e.target.value);
          // }}
          className=" rounded-md  bg-gray-50 border-2 min-w-[180px] px-4 py-2 text-xs sm:text-base text-black font-semibold cursor-pointer outline-none"
          type="date"
        />
      </div>
      <div className="p-3 flex justify-end mt-2  space-x-4 text-md">
        <button
          onClick={() => handleCancel()}
          className="transition-all duration-300 ease-in-out mb-2 md:mb-0 bg-white px-5 py-2 font-medium tracking-wider text-gray-900 rounded-md hover:shadow-lg border border-transparent
            + hover:border"
        >
          Cancel
        </button>
        <button
          onClick={() =>
            handleSubmit(startRef?.current?.value, endRef?.current?.value)
          }
          className="transition-all duration-300 ease-in-out bg-bodyBrown bg-lemonGreen mb-2 md:mb-0 text-white px-5 py-2 shadow-sm font-medium tracking-wider  rounded-md hover:shadow-lg "
        >
          Apply
        </button>
      </div>
    </div>
  );
};
export default ModifiedDateRangePicker;
