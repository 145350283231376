import React, { Fragment, useLayoutEffect, useState } from "react";
import Sidebar from "../sidebar/sidebar";
import { HiMenuAlt1 } from "react-icons/hi";
import { Listbox, Menu, Transition } from "@headlessui/react";
import { FiChevronDown } from "react-icons/fi";
import { logOut } from "../../../redux/slices/user-slice";
import { useDispatch, useSelector } from "react-redux";
import { LOGO, USERNAME } from "../../../constants/end-points";
// import DateRangePicker from "../date-range-picker/date-range-picker";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { AiFillCaretDown } from "react-icons/ai";
import { setDates } from "../../../redux/slices/report-slice";
import ModalOverlay from "../modal-overlay/modal-overlay2";
import ModifiedDateRangePicker from "../date-range-picker/modified-date-range-picker";
import { errorToast } from "../toastify/toastify";
import {
  formatDate,
  getTodaysDate,
  getTommorowsDate,
} from "../../libraries/easy";

const PageWrap = ({ children }) => {
  const [sideToggle, setSideToggle] = useState(true);
  const { startDate, endDate } = useSelector((state) => state.reports);
  const dispatch = useDispatch();
  const [endMinDate, setEndMinDate] = useState(startDate);
  const [timeRange, setTimeRange] = useState("Today");
  const [showDatePicker, setShowDatePicker] = useState({
    display: false,
    previous: "",
  });
  const pathName = window.location.pathname;

  const handleSidebar = () => {
    if (window && window.innerWidth < 900) {
      setSideToggle(false);
    }
  };

  useLayoutEffect(() => {
    if (window && window.innerWidth < 900) {
      setSideToggle(false);
    }
  }, [pathName]);

  return (
    <div className="w-full h-full">
      {showDatePicker.display && (
        <ModalOverlay setModalOn={setShowDatePicker} type="datePicker">
          <div className="flex border-2 z-50 bg-white rounded-md  justify-start items-center shadow-md">
            <ModifiedDateRangePicker
              defaultStart={startDate}
              defaultEnd={endDate}
              endMinDate={endMinDate}
              handleStartChange={(start) => {
                setEndMinDate(start);
              }}
              handleSubmit={(startRef, endRef) => {
                if (startRef === endRef) {
                  errorToast("Start and end dates cannot be the same");
                  return;
                }
                dispatch(setDates({ startDate: startRef, endDate: endRef }));
                // updateStartEndDate(startRef, endRef);
                setShowDatePicker(false);
                if (
                  startRef === getTodaysDate() &&
                  endRef === getTommorowsDate()
                ) {
                  setTimeRange("Today");
                } else {
                  setTimeRange(formatDate(startRef, endRef));
                }
              }}
              handleCancel={() => {
                let prev = showDatePicker.previous;
                setTimeRange(prev);
                setShowDatePicker(false);
              }}
            />
          </div>
        </ModalOverlay>
      )}
      <Sidebar
        setSideToggle={setSideToggle}
        sideToggle={sideToggle}
        handleSidebar={handleSidebar}
      />
      <div
        className={`${
          sideToggle && "lg:pl-[260px]"
        } flex flex-col flex-1 h-full justify-start `}
      >
        <div className="sticky top-0 flex-shrink-0 bg-lemonGreen flex z-20 h-12 w-full lg:border-none shadow py-8 px-2 sm:px-4 justify-center items-center">
          <div className=" relative w-full flex justify-between items-center">
            <HiMenuAlt1
              onClick={() => {
                setSideToggle(!sideToggle);
              }}
              className="absolute right-0 top-[75px] sm:right-0 sm:top-0 sm:relative w-7 h-7 cursor-pointer text-gray-600"
            />
            {/* <div className="bg-[#101321] h-[28px] sm:h-[37px] rounded-full flex items-start "> */}
            {/* <DateRangePicker
                handleChange={(startDate, endDate) => {
                  dispatch(setDates({ startDate, endDate }));
                }}
              /> */}
            <div className=" ">
              <div className="flex items-center justify-between">
                <span className="hidden sm:block">Showing:</span>
                <div className="h-10">
                  <Listbox value={timeRange} onChange={setTimeRange}>
                    <div className="relative mx-2 w-fit h-full min-w-[160px] justify-center items-center">
                      <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1 pl-3 pr-10 mt-1 text-left  border border-gray-300 focus:outline-none focus:placeholder-gray-400  focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                        <span className="block text-sm truncate">
                          {timeRange}
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <AiFillCaretDown
                            className="h-3 w-3 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                      </Listbox.Button>
                      <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          <div className="">
                            <Listbox.Option
                              onClick={() => {
                                let prev = timeRange;
                                // dispatch(
                                //   setDates({
                                //     startDate: getTodaysDate(),
                                //     endDate: getTommorowsDate(),
                                //   })
                                // );
                                setShowDatePicker({
                                  previous: prev,
                                  display: false,
                                });
                              }}
                              className={({ active }) =>
                                `relative cursor-default select-none py-2 flex items-center justify-center pr-4 ${
                                  active
                                    ? "bg-[#0c103455] text-[#0C1034]"
                                    : "text-gray-900"
                                }`
                              }
                              value={"Today"}
                            >
                              {({ selected }) => (
                                <>
                                  <span
                                    className={`block truncate text-xs capitalize ${
                                      selected ? "font-medium " : "font-normal"
                                    }`}
                                  >
                                    Today
                                  </span>
                                  {selected ? (
                                    <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-[#0C1034]">
                                      <IoMdCheckmarkCircleOutline
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                            <Listbox.Option
                              onClick={() => {
                                let prev = timeRange;

                                setShowDatePicker({
                                  previous: prev,
                                  display: true,
                                });
                              }}
                              className={({ active }) =>
                                `relative cursor-default select-none py-2 flex items-center justify-center pr-4 ${
                                  active
                                    ? "bg-[#0c103455] text-[#0C1034]"
                                    : "text-gray-900"
                                }`
                              }
                              value={"custom date"}
                            >
                              {({ selected }) => (
                                <>
                                  <span
                                    className={`block truncate text-xs capitalize ${
                                      selected ? "font-medium " : "font-normal"
                                    }`}
                                  >
                                    custom date range
                                  </span>
                                  {selected ? (
                                    <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-[#0C1034]">
                                      <IoMdCheckmarkCircleOutline
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          </div>
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </Listbox>
                </div>
              </div>
            </div>
            {/* </div> */}

            <div className="flex items-center justify-center">
              <div className="flex items-center justify-center">
                <img
                  className="bg-none mx-1 sm:mx-3 w-6 h-6 sm:w-10 sm:h-10"
                  src={LOGO()}
                  alt="logo"
                />
                <Menu as="div" className="relative">
                  <Menu.Button className="flex space-x-2 text-xs items-center justify-between py-3 w-full sm:text-sm">
                    <h3>{USERNAME()}</h3>
                    <FiChevronDown className="text-gray-500" />
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-800"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute shadow-md right-0 w-[150px] z-50 flex flex-col bg-white">
                      <hr className="w-full" />
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            onClick={() => {
                              dispatch(logOut());
                              window.location.assign("/");
                            }}
                            className={`${
                              active && "bg-gray-100 rounded-full"
                            } text-center text-sm w-full text-red-500 py-2`}
                          >
                            Sign Out
                          </button>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>
        </div>
        <main className="flex flex-col p-0 mt-5 lg:mt-0 md:p-10">
          {children}
        </main>
      </div>
    </div>
  );
};

export default PageWrap;
