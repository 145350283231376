import React from "react";
import { Outlet } from "react-router-dom";
// import { URLS } from "../../constants/route-links";
// import { User } from "../../constants/ui-data";
import PageWrap from "../../tools/components/page-wrap/page-wrap";

const Home = () => {
  return (
    <PageWrap>
      <Outlet />
    </PageWrap>
  );
};

export default Home;
