import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { END_POINTS } from "../../constants/end-points";
import {
  errorToast,
  successToast,
} from "../../tools/components/toastify/toastify";
import { getTodaysDate, getTommorowsDate } from "../../tools/libraries/easy";
import { paymentRequest } from "./requests/paymentRequest";

// Add Agent
export const getPaymentReports = createAsyncThunk(
  "get-payment-reports",
  async ({ startDate, endDate }, thunkAPI) => {
    try {
      return paymentRequest(
        `${END_POINTS.getReports}/${startDate}/end/${endDate}`,
        "GET"
      );
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getTransactionDetails = createAsyncThunk(
  "get-transaction-details",
  async ({ startDate, endDate, transactionType }, thunkAPI) => {
    try {
      return paymentRequest(
        `${END_POINTS.getTransactionDetails}/${startDate}/end/${endDate}/status/${transactionType}`,
        "GET"
      );
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const sendTransactionAcknowledgement = createAsyncThunk(
  "send-acknowledgement",
  async (thunkAPI) => {
    try {
      return paymentRequest(END_POINTS.sendTransactionAcknowledgement, "GET");
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getChannelPaymentDetails = createAsyncThunk(
  "get-channel-payment-details",
  async ({ channel, startDate, endDate }, thunkAPI) => {
    try {
      return paymentRequest(
        `${END_POINTS.getChannelPaymentDetails}/${channel}/start/${startDate}/end/${endDate}`,
        "GET"
      );
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const initialState = {
  report: {},
  payments: [],
  transactionDetails: [],
  transactionType: "total",
  loading: false,
  crudLoading: false,
  startDate: getTodaysDate(),
  endDate: getTommorowsDate(),
};

export const reportSlice = createSlice({
  name: "agent",
  initialState,
  reducers: {
    setTransactionType: (state, { payload }) => {
      state.transactionType = payload;
    },
    setDates: (state, { payload }) => {
      if (payload.endDate < payload.startDate) {
        return errorToast("End date must be greater than start date");
      }
      state.startDate = payload.startDate;
      state.endDate = payload.endDate;
    },
  },
  extraReducers: {
    //! Get Payment Report
    [getPaymentReports.pending]: (state) => {
      state.loading = true;
    },
    [getPaymentReports.fulfilled]: (state, { payload }) => {
      if (!!payload) {
        state.report = payload.data;
      } else {
        errorToast(payload.message);
      }
      state.loading = false;
    },
    [getPaymentReports.rejected]: (state) => {
      state.loading = false;
      errorToast("Error fetching payment data");
    },

    //! Get Transactions
    [getTransactionDetails.pending]: (state) => {
      state.loading = true;
    },
    [getTransactionDetails.fulfilled]: (state, { payload }) => {
      if (!!payload && payload.status === true) {
        state.transactionDetails = payload.data?.sort(
          (a, b) => new Date(b.paymentDate) - new Date(a.paymentDate)
        );
      } else {
        errorToast(payload.message);
      }
      state.loading = false;
    },
    [getTransactionDetails.rejected]: (state) => {
      state.loading = false;
      errorToast("Error fetching transactions data");
    },

    //! Send Acknowledgment
    [sendTransactionAcknowledgement.pending]: (state) => {
      state.loading = true;
    },
    [sendTransactionAcknowledgement.fulfilled]: (state, { payload }) => {
      if (!!payload && payload.status === 200) {
        successToast("Acknowledgement sent successfully");
      } else {
        errorToast(payload.message);
      }
      state.loading = false;
    },
    [sendTransactionAcknowledgement.rejected]: (state) => {
      state.loading = false;
      errorToast("Error fetching transactions data");
    },

    //! Get Channel Payment Detail
    [getChannelPaymentDetails.pending]: (state) => {
      state.loading = true;
    },
    [getChannelPaymentDetails.fulfilled]: (state, { payload }) => {
      if (!!payload && payload.status === true) {
        state.payments = payload.data.sort(
          (a, b) => new Date(b.paymentDate) - new Date(a.paymentDate)
        );
      } else {
        errorToast(payload.message);
      }
      state.loading = false;
    },
    [getChannelPaymentDetails.rejected]: (state) => {
      state.loading = false;
      errorToast("Error fetching payment data");
    },
  },
});

export const { setDates, setTransactionType } = reportSlice.actions;
export default reportSlice.reducer;
