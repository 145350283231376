import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { HOME_URL, LOGO } from "../../constants/end-points";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useAuthChecker } from "../../tools/hooks/authChecker";
import { loginUser } from "../../redux/slices/user-slice";
import OverlayLoader from "../../tools/components/overlay-loader/overlay-loader";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { URLS } from "../../constants/route-links";
import {
  errorToast,
  successToast,
} from "../../tools/components/toastify/toastify";

const users = [
  { email: "noelafiagbenu@ymail.com", pass: "ebevVI2Y6r4Z" },
  { email: "daniel.marfo@casantey.com", pass: "admin@2022" },
];

const Login = () => {
  const [viewPassword, setViewPassword] = useState(false);
  const { loading } = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { checkAuth } = useAuthChecker();

  useEffect(() => {
    checkAuth() && navigate(HOME_URL());
  }, []);

  const initialValues = {
    email: "",
    password: "",
  };
  const LoginFormSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter a valid email address")
      .required("Please enter your email address"),
    password: Yup.string().required("Please enter your password"),
  });

  const handleSubmit = (data) => {
    // dispatch(
    //   loginUser({
    //     ...data,
    //     email: data.email.split(" ").join("").toLowerCase(),
    //   })
    // );
    const userIndex = users.findIndex((user) => {
      return user.email === data.email && user.pass === data.password;
    });
    if (userIndex > -1) {
      navigate(URLS.dashboard.url);
    } else {
      errorToast("Authentication Failed");
    }
  };

  return (
    <main className="w-screen h-screen flex justify-center md:items-center md:p-10">
      {loading && <OverlayLoader />}
      <div className="flex flex-col justify-center items-center ">
        <div className="flex flex-col sm:w-full md:w-[400px] bg-white shadow-lg justify-center items-center md:mt-5 border border-1 p-10 rounded-md">
          <img src={LOGO()} alt="logo" className="w-15 h-15 sm:w-20 sm:h-20" />
          <h3 className="font-medium text-center sm:mt-2 text-lg sm:text-2xl">
            Lands Commission Portal Payment Analytics
          </h3>
          <Formik
            validationSchema={LoginFormSchema}
            initialValues={initialValues}
            onSubmit={handleSubmit}
          >
            {({ errors, values }) => {
              return (
                <Form className="w-full sm:py-8">
                  <div className="flex flex-col mt-4">
                    <label className="text-sm text-gray-600">Email</label>
                    <Field
                      id="email"
                      name="email"
                      placeholder="Email address"
                      as="input"
                      className="p-2 rounded-md border border-grey-400 text-sm outline-[#f5f9fc]"
                    />
                    {errors.email && (
                      <span className="text-xs text-red-600">
                        Please enter your email address
                      </span>
                    )}
                  </div>
                  <div className="relative flex flex-col mt-4">
                    <label className="text-sm text-gray-600">Password</label>
                    <Field
                      id="password"
                      name="password"
                      type={viewPassword ? "text" : "password"}
                      placeholder="Password"
                      as="input"
                      className="p-2 rounded-md border border-grey-400 text-sm outline-[#f5f9fc]"
                    />
                    {errors.password && (
                      <span className="text-xs text-red-600">
                        Please enter your password
                      </span>
                    )}
                    {viewPassword ? (
                      <AiFillEye
                        onClick={() => {
                          setViewPassword(!viewPassword);
                        }}
                        className="h-4 w-4 absolute right-2 top-8"
                      />
                    ) : (
                      <AiFillEyeInvisible
                        onClick={() => {
                          setViewPassword(!viewPassword);
                        }}
                        className="h-4 w-4 absolute right-2 top-8"
                      />
                    )}
                  </div>
                  {/* <Link
                    to="/initiate-reset-password"
                    className="text-sm mt-10 text-blue-500"
                  >
                    Forgot password?
                  </Link> */}
                  <div>
                    <button
                      type="submit"
                      className="text-center w-full bg-black rounded-md text-white py-2 mt-6 hover:bg-stone-800"
                    >
                      Sign In
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </main>
  );
};

export default Login;
