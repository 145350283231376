import { getAsObjectFromLocalStorage } from "../tools/libraries/easy";

export const User = () => {
  let userData = getAsObjectFromLocalStorage("lcReportUser");
  if (!!userData) {
    return userData;
  }
  return { name: "" };
};

export const headings = {
  paymentHistory: [
    "Bill No.",
    "Date Created",
    "Description",
    "Payment Status",
    "Payment Amount",
    "Payment Date",
    "Receipt No.",
    "Download Receipt",
  ],
  channelHeadings: [
    "sn",
    "channel",
    "no. of transactions",
    "bill amount",
    "publications (accra)",
    "publications (tema)",
    "paid amount",
  ],
  paymentHeadings: [
    "sn",
    "bill number",
    "description",
    "payment source",
    "payment date",
    "amount",
    "egcr no.",
    "acknowledged",
    "acknowledged date",
    "name of person",
    "payment method",
  ],
};
