import React, { useEffect, useRef, useState } from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import useTable from "../../hooks/useTable";
import { getChannelPaymentDetails } from "../../../redux/slices/report-slice";

const PaymentDetailTable = ({ data }) => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const { loading, startDate, endDate, payments } = useSelector(
    (state) => state.reports
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getChannelPaymentDetails({ channel: data.channel, startDate, endDate })
    );
  }, [dispatch, data.channel, startDate, endDate]);

  const { range, slice, baseIndex } = useTable(payments, page, rowsPerPage);

  const searchRef = useRef();
  const rowsRef = useRef();

  const handleChange = () => {
    const { value } = searchRef.current;
    if (value !== "") {
      setPage(1);
      rowsRef.current.value = payments.length;
      setRowsPerPage(payments.length);
    } else {
      rowsRef.current.value = 10;
      setRowsPerPage(10);
    }
    setSearch(value);
  };

  const totalAmount = payments?.reduce((acc, item) => {
    const total = acc + item.paymentAmount;
    return total;
  }, 0);

  return (
    <>
      <div className="flex flex-col items-between w-full justify-center px-1 sm:px-4 lg:px-10 pt-10 mb-10">
        <div className="w-full">
          <div className="flex justify-between items-center mb-8">
            <h1 className="font-bold text-xs lg:text-lg text-dark">
              {data?.channel} Payment Records - {payments?.length} Records
            </h1>
            <input
              ref={searchRef}
              id="search-field"
              name="search-field"
              className="block w-full sm:max-w-[200px] max-w-[120px] h-full px-3 py-1 text-gray-700 text-md placeholder-gray-500 focus:ring-0 focus:placeholder-gray-400 text-sm md:text-normal border border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
              placeholder="Search By Bill Number"
              type="search"
              onChange={handleChange}
            />
          </div>

          <div className="flex justify-between flex-wrap">
            <p className="mr-2">
              Total Payment Amount:{" "}
              <span className="text-bold text-green-700 font-bold ">
                GHS {Number(totalAmount?.toFixed(2)).toLocaleString()}
              </span>
            </p>
            <p className="flex justify-end mt-2 flex-col xs:flex-row sm:mt-0">
              <span className="mr-2 mb-2 xs:mb-0">Start Date: {startDate}</span>
              <span>End Date: {endDate}</span>
            </p>
          </div>
        </div>
      </div>
      <div className="overflow-x-scroll">
        <table className="overflow-x-scroll table-auto w-full">
          <thead className="text-left">
            <tr className="border-t border-gray-200">
              <th
                className="px-3 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                scope="col"
              >
                SN.
              </th>
              <th
                className="px-3 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                scope="col"
              >
                Bill Number
              </th>
              <th
                className="px-3 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                scope="col"
              >
                Description
              </th>
              <th
                className="px-3 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                scope="col"
              >
                Payment Source
              </th>
              <th
                className="px-3 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                scope="col"
              >
                Payment Date
              </th>
              <th
                className="px-3 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                scope="col"
              >
                Amount (GHS)
              </th>
              <th
                className="px-3 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                scope="col"
              >
                EGCR No.
              </th>
              <th
                className="px-3 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                scope="col"
              >
                Acknowledged
              </th>
              <th
                className="px-3 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                scope="col"
              >
                Acknowledged Date
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan="9" className="my-100 border-spacing-y-7">
                  <div className="w-full my-4 bg-opacity-60 z-[900] flex justify-center items-center">
                    <div className="w-16 h-16 animate-spin border-t-2 border-b-2 border-dark rounded-full"></div>
                  </div>
                </td>
              </tr>
            ) : slice?.length === 0 ? (
              <tr>
                <td
                  colSpan="8"
                  className="text-center py-10 text-2xl font-sm font-arial-rounded text-gray-600"
                >
                  No Records Available
                </td>
              </tr>
            ) : (
              slice
                ?.filter((item) => {
                  if (search !== "") {
                    return item?.billNo.includes(search);
                  } else {
                    return item;
                  }
                })
                .map((item, index) => {
                  return (
                    <tr key={index} className="border">
                      <td className="px-3 py-3 text-sm text-gray-700">
                        {baseIndex + index + 1}
                      </td>
                      <td className="px-3 py-3 text-sm text-gray-700">
                        {item?.billNo}
                      </td>
                      <td className="px-3 py-3 text-sm text-gray-700">
                        {item?.description}
                      </td>
                      <td className="px-3 py-3 text-sm text-gray-700">
                        {item?.paymentSource}
                      </td>
                      <td className="px-3 py-3 text-sm text-gray-700">
                        {item?.paymentDate}
                      </td>
                      <td className="px-3 py-3 text-sm text-gray-700">
                        {Number(
                          item?.paymentAmount?.toFixed(2)
                        ).toLocaleString()}
                      </td>
                      <td className="px-3 py-3 text-sm text-gray-700">
                        {item?.egcrNo}
                      </td>
                      <td className="px-3 py-3 text-sm text-gray-700">
                        {item?.backOfficeUpdateStatus === "1" ? "Yes" : "No"}
                      </td>
                      <td className="px-3 py-3 text-sm text-gray-700">
                        {item?.backOfficeUpdateDate
                          ? item?.backOfficeUpdateDate
                          : "-"}
                      </td>
                    </tr>
                  );
                })
            )}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan="9" className="text-right justify-end p-3">
                <div className="flex justify-end items-center">
                  <p className="text-gray-400 text-sm select-none">
                    Rows per page:{" "}
                  </p>
                  <select
                    ref={rowsRef}
                    onChange={(e) => {
                      setPage(1);
                      setRowsPerPage(e.target.value);
                    }}
                    className="ml-2 text-gray-600 outline-none text-sm p-1 select-none"
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value={payments.length}>All</option>
                  </select>
                  <p className="ml-5 text-gray-400 text-sm select-none">
                    {slice.length ? page : 0} of {range.length}
                  </p>
                  <div className="ml-5 flex">
                    <FiChevronLeft
                      onClick={() => {
                        if (page > 1) {
                          setPage(page - 1);
                        }
                      }}
                      className="mx-2 w-6 h-6 hover:text-gray-500 rounded-full text-gray-400 cursor-pointer"
                    />
                    <FiChevronRight
                      onClick={() => {
                        if (page < range.length) {
                          setPage(page + 1);
                        }
                      }}
                      className="mx-2 w-6 h-6 hover:text-gray-500 rounded-full text-gray-400 cursor-pointer"
                    />
                  </div>
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </>
  );
};

export default PaymentDetailTable;
