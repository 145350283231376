import logo from "../assets/images/lc-logo.png";
import { URLS } from "./route-links";
import { User } from "../constants/ui-data";


export const BASE_URL = {
  dev: "https://api.lc.casantey.com",
  // dev: "https://4350-102-176-94-23.ngrok.io",
};
export const PAYMENT_BASE_URL = {
  dev: "https://payment.casantey.com",
  // dev: "",
};
export const PAYMENT_TOKEN = {
  dev: "TENQb3J0YWw6UEB5bW4zTlRAY2JzZw==",
};
export const EGCR_TOKEN = {
  // dev: "K0rDNV5zn7UdQsYjMJpRziRBsc3R80KM7MT8Q/O2Ddg=",
  dev: "nbNe++11+wVPnZmPz+BTSkkxd0hCQsIURKp7cHnpcnw=",
};
export const BASE_TOKEN = {
  dev: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJMQyIsImlzcyI6IlN5c19BZG1pbiIsImV4cCI6MTY5MjYwMzY1Mn0.3GCTZLfHpqLniNld2w8CDI4oJLd1nps3jJvTIK5dIvM",
  // dev: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJMQyIsImlzcyI6IlN5c19BZG1pbiIsImV4cCI6MTY5MDk4MDAzOH0.yIz7gyDkBDvVtgx47jRcbLJLPtgqZjox16PF8ErMHuo", //Local
};

//!Dev Port = 9293
//!Staging Port = 9294
//!Production Port = 8286
const lcPort = 8286;
const documentPort = 8283;

export const END_POINTS = {
  //Authentication
  activateCustomer: "/customer/activate-customer",
  createCustomer: "/customer/create-customer",
  customerAuthenticate: "/customer/authenticate",
  verifyActivationLink: "/customer/verifyActivationLinkStatus",
  loginUser: "/customer/authenticate",
  initiatePasswordReset: "/customer/initiate-password-reset",
  resetPassword: "/customer/reset-password",
  verifyResetLink: "/customer/verifyResetLinkStatus",
  createVerificationPin: "/customer/create-verification-pin",

  //Email
  sendComplaintEmail: "/customer/sendComplaintEmail",
  sendAcknowledgementEmail: "/customer/send-acknowledgement-email",
  sendEGCREmail: "/customer/send-egcr-email",

  //Jobs
  pendingJobs: "/landing/pendingJobs",
  queriedJobs: "/landing/queriedJobs",
  completedJobs: "/landing/completedJobs",

  //Dashboard
  cardData: "/landing/dashboard",
  unpaidBills: "/landing/unpaidBills",
  getPaidBills: "/landing/paidBills",

  //Tracker
  trackJob: "/landing/trackJob",

  //Services
  mainServices: "/services/mainServices",
  subServices: "/services/subServices",
  checkList: "/services/checkList",
  regions: "/services/regions",
  locality: "/services/locality",
  generateBill: "/services/generateBill",
  paymentUrl: "/PaymentUtil/api/createInvoice",
  checkPaymentStatus: "/PaymentUtil/api/Hubtel/PaymentStatusCheck",
  paymentDetails: "/PaymentUtil/api/paymentDetails/CheckoutID",
  paymentDetailsClientRef: "/PaymentUtil/api/paymentDetails/ClientReference",
  paymentDetailsBillId: "/PaymentUtil/api/paymentDetails/BillID",
  getPaymentHistory: "/PaymentUtil/api/paymentHistory/email",
  getReports: "/PaymentUtil/api/payment-dashboard/start",
  getChannelPaymentDetails: "/PaymentUtil/api/paymentSource",
  getTransactionDetails: "/PaymentUtil/api/payment-dashboard-data/start",
  sendTransactionAcknowledgement:
    "/PaymentUtil/api/push-unAcknowledgedPaymentsToday",

  //Url Downloads
  billUrl:
    "https://limitless-eyrie-86093.herokuapp.com/http://41.66.204.146:" +
    lcPort +
    "/gelis_online_service_live/rest/case_management_service/get_bill_pdf_online",
  receiptUrl:
    "https://limitless-eyrie-86093.herokuapp.com/https://uat-cagdmdaapi.paqtechnologies.com/v1/receipts/createreceiptandfile",
  // blob: "https://limitless-eyrie-86093.herokuapp.com/http://41.66.204.146:" + lcPort + "/gelis_online_service_live/rest/case_management_service/get_bill_pdf_online",
  // blob: "https://limitless-eyrie-86093.herokuapp.com/https://cagdmdaapi.paqtechnologies.com/v1/receipts/LC000000181640/receiptfile",

  lcCaseDocument:
    "https://limitless-eyrie-86093.herokuapp.com/http://41.66.204.146:" +
    documentPort +
    "/gelis_online_service_live/rest/case_management_service/get_download_public_document_file_online",

  //LC Acknowledgement Url
  lcAcknowledgement:
    // "https://limitless-eyrie-86093.herokuapp.com/http://41.66.204.146:" + lcPort + "/gelis_online_service_live/rest/case_management_service/online_select_process_acknowledgement_for_online",
    "https://limitless-eyrie-86093.herokuapp.com/http://41.66.204.146:" +
    lcPort +
    "/gelis_online_service_live/rest/case_management_service/online_select_process_acknowledgement_for_online_new",

  //Document Upload
  documentUpload:
    "https://limitless-eyrie-86093.herokuapp.com/http://41.66.204.146:" +
    documentPort +
    "/elis/rest/file_mangement/file_upload_public",

  queryDocumentUpload:
    // "https://limitless-eyrie-86093.herokuapp.com/http://41.66.204.146:" +
    // lcPort +
    // "/elis/rest/file_mangement/file_upload",
    "https://limitless-eyrie-86093.herokuapp.com/http://41.66.204.146:" +
    documentPort +
    "/elis/rest/file_mangement/file_upload",

  getEGCRUrl:
    // "https://limitless-eyrie-86093.herokuapp.com/https://uat-cagdmdaapi.paqtechnologies.com/v1/receipts",
    "https://limitless-eyrie-86093.herokuapp.com/https://cagdmdaapi.paqtechnologies.com/v1/receipts/",
};

export const HOME_URL = () => {
  return URLS.dashboard.url;
};
export const LOGO = () => logo;

export const USERNAME = () => User()?.name;

export const EMAIL = () => User()?.email;

export const PHONE = () => User()?.phone;

export const ADDRESS = () => User()?.address;

export const ACCOUNT_TYPE = () => User()?.type;
