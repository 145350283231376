import { URLS } from "./route-links";
import { BsFillPeopleFill } from "react-icons/bs";
import { MdOutlineSpaceDashboard } from "react-icons/md";

export const navigations = [
  {
    name: "Dashboard",
    icon: MdOutlineSpaceDashboard,
    link: URLS.dashboard.route,
  },
  // {
  //   name: "Users",
  //   icon: BsFillPeopleFill,
  //   link: URLS.users.route,
  // },
];
