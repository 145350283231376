export const baseRoute = {
  route: "/home",
  url: "/home",
};

export const URLS = {
  home: {
    route: baseRoute.route,
    url: baseRoute.url,
  },
  login: {
    route: "/login",
    url: "/login",
  },
  initiatePasswordReset: {
    route: "/initiate-password-reset",
    url: "/initiate-password-reset",
  },
  resetPassword: {
    route: "/reset-password",
    url: "/reset-password",
  },
  dashboard: {
    route: "dashboard",
    url: baseRoute.route + "/dashboard",
  },
  users: {
    route: "users",
    url: baseRoute.route + "/users",
  },
  reportDetail: {
    route: "report-detail",
    url: baseRoute.route + "/report-detail",
  },
};
