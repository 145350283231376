import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";

const CSVExport = ({ headings, csvData }) => {
  const [finalData, setFinalData] = useState([]);
  useEffect(() => {
    setFinalData([[...headings], ...csvData]);
  }, [headings, headings?.length, csvData]);

  return (
    <CSVLink
      data={finalData}
      className="hover:bg-green-700 text-sm bg-lemonGreen px-2 py-1 text-white rounded-md"
    >
      Export to CSV
    </CSVLink>
  );
};

export default CSVExport;
