import React, { useEffect, useState } from "react";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { HiOutlineClipboardList } from "react-icons/hi";
import { MdPendingActions, MdShowChart } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { digitsFormat, getTotalInList } from "../../../tools/libraries/easy";
import CircularProgressIndicator from "../../../tools/components/circular-progress-indicator/circular-progress-indicator";
import CSVExport from "../../../tools/components/csv-export/csv-export";
import ModalOverlay from "../../../tools/components/modal-overlay/modal-overlay";
import { headings } from "../../../constants/ui-data";
import PaymentDetailTable from "../../../tools/components/payment-detail-table/payment-detail-table";
import {
  getPaymentReports,
  setTransactionType,
} from "../../../redux/slices/report-slice";
import { URLS } from "../../../constants/route-links";

const Dashboard = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedData, setSelectedData] = useState(false);
  const { loading, report, startDate, endDate } = useSelector(
    (state) => state.reports
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const ejectSaleSummeryData = () => {
    const cards = [
      {
        icon: <HiOutlineClipboardList className="w-8 h-8 text-white" />,
        icon2: <MdShowChart className="w-8 h-8 text-sky-500" />,
        value: !loading ? (
          digitsFormat(report?.totalTransactions)
        ) : report?.totalTransactions === 0 ? (
          0
        ) : (
          <CircularProgressIndicator />
        ),
        description: "Total",
        color: "from-sky-500",
        color2: "to-sky-700",
      },
      {
        icon: <MdPendingActions className="w-8 h-8 text-white" />,
        icon2: <MdShowChart className="w-8 h-8 text-orange-500 rotate-90" />,
        value: !loading ? (
          digitsFormat(report?.pendingTransactions)
        ) : report?.pendingTransactions === 0 ? (
          0
        ) : (
          <CircularProgressIndicator />
        ),
        description: "Pending",
        color: "from-yellow-500",
        color2: "to-yellow-700",
      },
      {
        icon: <AiOutlineCheck className="w-8 h-8 text-white" />,
        icon2: <MdShowChart className="w-8 h-8 text-fuchsia-500" />,
        value: !loading ? (
          digitsFormat(report?.paidTransactions)
        ) : report?.paidTransactions === 0 ? (
          0
        ) : (
          <CircularProgressIndicator />
        ),
        description: "Paid",
        color: "from-emerald-500",
        color2: "to-emerald-700",
      },
      {
        icon: <AiOutlineClose className="w-8 h-8 text-white" />,
        icon2: <MdShowChart className="w-8 h-8 text-emerald-500  rotate-180" />,
        value: !loading ? (
          digitsFormat(report?.failedTransactions)
        ) : report?.failedTransactions === 0 ? (
          0
        ) : (
          <CircularProgressIndicator />
        ),
        description: "Failed",
        color: "from-red-500",
        color2: "to-red-700",
      },
      {
        icon: <AiOutlineCheck className="w-8 h-8 text-white" />,
        icon2: <MdShowChart className="w-8 h-8 text-indigo-500 rotate-90" />,
        value: !loading ? (
          digitsFormat(report?.acknowledgedTransactions)
        ) : report?.acknowledgedTransactions === 0 ? (
          0
        ) : (
          <CircularProgressIndicator />
        ),
        description: "No. of Acknowledged",
        color: "from-cyan-500",
        color2: "to-cyan-700",
        path: "acknowledged",
      },
      {
        icon: <AiOutlineClose className="w-8 h-8 text-white" />,
        icon2: <MdShowChart className="w-8 h-8 text-cyan-500" />,
        value: !loading ? (
          digitsFormat(report?.unacknowledgedTransactions)
        ) : report?.unacknowledgedTransactions === 0 ? (
          0
        ) : (
          <CircularProgressIndicator />
        ),
        description: "No. of Unacknowledged",
        color: "from-orange-500",
        color2: "to-orange-700",
        path: "unacknowledged",
      },
    ];
    return (
      Array.isArray(cards) &&
      cards.map((item, index) => {
        if (report?.totalTransactions && !loading) {
          return (
            <button
              key={index}
              onClick={() => {
                dispatch(
                  setTransactionType(
                    item?.path
                      ? item?.path?.toLowerCase()
                      : item?.description?.toLowerCase()
                  )
                );
                navigate(
                  `${URLS.reportDetail.url}/${
                    item?.path
                      ? item.path.toLowerCase()
                      : item.description.toLowerCase()
                  }-transactions`
                );
              }}
              // to={`${URLS.reportDetail.url}/${
              //   item?.path
              //     ? item.path.toLowerCase()
              //     : item.description.toLowerCase()
              // }-transactions`}
              className={`flex flex-col grow whitespace-normal w-full justify-between text-left sm:w-64 sm:h-48 shadow-md hover:shadow-lg my-2 px-4 py-4 sm:mr-3 bg-gradient-to-b ${item.color} ${item.color2}`}
            >
              <div key={index}>
                <div>
                  <div className="flex justify-between my-2 items-center">
                    {item.icon}
                  </div>
                  <div className="w-full">
                    <h3 className="font-light text-sm text-white">
                      {item.description} Transactions
                    </h3>
                    <h1 className="font-semibold text-3xl text-white">
                      {item?.value ? item?.value : 0}
                    </h1>
                  </div>
                </div>
              </div>
            </button>
          );
        }
        return (
          <div
            key={index}
            className={`flex flex-col grow whitespace-normal justify-between sm:w-64 sm:h-48 shadow-md hover:shadow-lg my-2 px-4 py-4 sm:mr-3 bg-gradient-to-b ${item.color} ${item.color2}`}
          >
            <div>
              <div className="flex justify-between my-2 items-center">
                {item.icon}
              </div>
              <div className="w-full">
                <h3 className="font-light text-sm text-white">
                  {item.description} Transactions
                </h3>
                <h1 className="font-semibold text-3xl text-white">
                  {item?.value ? item?.value : 0}
                </h1>
              </div>
            </div>
          </div>
        );
      })
    );
  };

  useEffect(() => {
    dispatch(getPaymentReports({ startDate, endDate }));
  }, [dispatch, startDate, endDate]);

  const totalTransactions = getTotalInList(report?.channels, "transactions");
  const totalAmount = getTotalInList(report?.channels, "amount");
  const totalBillAmount = getTotalInList(report?.channels, "lca");
  const totalPBA = getTotalInList(report?.channels, "lcoa");
  const totalPBT = getTotalInList(report?.channels, "lcot");

  const csvData = () => {
    let arr = report?.channels?.map((item, index) => {
      return [
        index + 1,
        item.channel,
        digitsFormat(item.transactions),
        "GHS " + digitsFormat(item?.lca),
        "GHS " + digitsFormat(item?.lcoa),
        "GHS " + digitsFormat(item?.lcot),
        "GHS " + digitsFormat(item?.amount),
      ];
    });
    arr.push([
      report?.channels.length + 1,
      "All",
      digitsFormat(totalTransactions),
      "GHS " + digitsFormat(totalBillAmount),
      "GHS " + digitsFormat(totalPBA),
      "GHS " + digitsFormat(totalPBT),
      "GHS " + digitsFormat(totalAmount),
    ]);
    return arr;
  };
  return (
    <>
      {showModal && (
        <ModalOverlay setModalOn={setShowModal}>
          <PaymentDetailTable data={selectedData} />
        </ModalOverlay>
      )}
      <h1 className="text-2xl font-semibold">Payment Analytics</h1>
      <div className="flex w-full flex-wrap">
        {ejectSaleSummeryData()}
        <div className="w-full mt-10">
          <div className="flex space-x-2 mb-3 justify-between items-center">
            <h1 className="text-2xl ml-3">
              Distribution of Paid Transactions by Channel
            </h1>
            {!loading && report?.channels && (
              <CSVExport
                headings={headings.channelHeadings}
                csvData={csvData()}
              />
            )}
          </div>

          <div className="overflow-x-auto">
            <table className="overflow-scroll table-auto w-full">
              <thead className="text-left">
                <tr className="border-t border-gray-200">
                  {headings.channelHeadings?.map((item, index) => {
                    return (
                      <th
                        key={index}
                        className="px-3 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        scope="col"
                      >
                        {item}
                      </th>
                    );
                  })}
                  <th
                    className="px-3 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    scope="col"
                  >
                    View
                  </th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="9" className="border-spacing-y-7">
                      <div className="w-full my-4 bg-opacity-60 z-[900] flex justify-center items-center">
                        <CircularProgressIndicator
                          color="black"
                          h="16"
                          w="16"
                        />
                      </div>
                    </td>
                  </tr>
                ) : report?.channels?.length > 0 ? (
                  report?.channels.map((item, index) => {
                    return (
                      <tr key={index} className="border">
                        <td className="px-3 py-3 text-sm text-gray-700">
                          {index + 1}
                        </td>
                        <td className="px-3 py-3 text-sm text-gray-700">
                          {item?.channel}
                        </td>
                        <td className="px-3 py-3 text-sm text-gray-700">
                          {item?.transactions
                            ? digitsFormat(item?.transactions)
                            : "-"}
                        </td>
                        <td className="px-3 py-3 text-sm text-gray-700">
                          {item?.lca ? "GHS " + digitsFormat(item?.lca) : ""}
                        </td>
                        <td className="px-3 py-3 text-sm text-gray-700">
                          {item?.lcoa ? "GHS " + digitsFormat(item?.lcoa) : "-"}
                        </td>
                        <td className="px-3 py-3 text-sm text-gray-700">
                          {item?.lcot ? "GHS " + digitsFormat(item?.lcot) : "-"}
                        </td>

                        <td className="px-3 py-3 text-sm text-gray-700">
                          {item?.amount
                            ? "GHS " + digitsFormat(item?.amount)
                            : ""}
                        </td>
                        <td className="px-3 py-3 text-sm text-gray-700">
                          <button
                            onClick={() => {
                              setSelectedData(item);
                              setShowModal(true);
                            }}
                            className="text-white px-2 py-1 rounded-md text-sm bg-lemonGreen hover:bg-green-700"
                          >
                            View
                          </button>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td
                      colSpan="7"
                      className="text-center py-2 text-2xl font-sm font-arial-rounded text-gray-600"
                    >
                      No Transactions Available
                    </td>
                  </tr>
                )}
                {!loading && (
                  <tr className="border">
                    <td className="px-3 py-3 text-sm text-gray-700">
                      {report?.channels?.length
                        ? report?.channels?.length + 1
                        : "-"}
                    </td>
                    <td className="px-3 py-3 text-sm text-gray-700">All</td>
                    <td className="px-3 py-3 text-sm text-gray-700">
                      {totalTransactions
                        ? digitsFormat(totalTransactions)
                        : "-"}
                    </td>
                    <td className="px-3 py-3 text-sm text-gray-700">
                      GHS {totalAmount ? digitsFormat(totalBillAmount) : ""}
                    </td>
                    <td className="px-3 py-3 text-sm text-gray-700">
                      GHS {totalAmount ? digitsFormat(totalPBA) : ""}
                    </td>
                    <td className="px-3 py-3 text-sm text-gray-700">
                      GHS {totalAmount ? digitsFormat(totalPBT) : ""}
                    </td>

                    <td className="px-3 py-3 text-sm text-gray-700">
                      GHS {totalAmount ? digitsFormat(totalAmount) : ""}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
