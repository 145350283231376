import React from "react";
import { Link } from "react-router-dom";
import { LOGO } from "../../../constants/end-points";
import { navigations } from "../../../constants/navigations";

const Sidebar = ({ sideToggle, handleSidebar }) => {
  return (
    <aside
      className={`${
        sideToggle ? "translate-x-0 " : "translate-x-[-100%] "
      } h-full fixed w-[260px] py-4 bg-dashboardBG z-20 flex flex-col justify-between transition-all sm:duration-75 duration-500`}
    >
      <div className=" flex flex-col">
        <div className="flex items-center mx-5">
          <img className="w-8 h-8" src={LOGO()} alt="logo" />
          <h4 className="text-white ml-4 font-bold text-lg">
            Lands Commission
          </h4>
        </div>
        <h6 className="mt-10 text-gray-300 text-xs mb-2 ml-5">Main Menu</h6>
        {navigations.map((menu, index) => {
          return (
            <Link
              key={index}
              to={menu.link}
              className="flex space-x-2 items-center hover:bg-gray-800 hover:text-gray-300 py-3 text-gray-500 px-5 w-full text-sm"
              onClick={handleSidebar}
            >
              <menu.icon className="w-5 h-5" />
              <h3 className="text-gray-300">{menu.name}</h3>
            </Link>
          );
        })}
      </div>
      <div>
        <p className="text-gray-300 text-sm text-center">Version 1.0.0</p>
        <p className="text-gray-300 text-sm text-center">Powered By Casantey</p>
      </div>
    </aside>
  );
};

export default Sidebar;
