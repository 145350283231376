import { PAYMENT_BASE_URL, PAYMENT_TOKEN } from "../../../constants/end-points";

export const paymentRequest = async (path, method = "GET", data) => {
  const controller = new AbortController();
  const signal = controller.signal;
  let url = `${PAYMENT_BASE_URL.dev}${path}`;
  if (method === "GET" && !!data) {
    const params = new URLSearchParams(data);
    url += `?${params.toString()}`;
  }
  return fetch(url, {
    method,
    headers: {
      Authorization: PAYMENT_TOKEN.dev,
      "Content-Type": "application/json",
    },
    body: method !== "GET" && !!data ? JSON.stringify(data) : undefined,
    signal: signal,
  })
    .then(async (response) => {
      return await response.json();
    })
};