import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { headings } from "../../../constants/ui-data";
import {
  getTransactionDetails,
  sendTransactionAcknowledgement,
} from "../../../redux/slices/report-slice";
import CSVExport from "../../../tools/components/csv-export/csv-export";
import ModalOverlay from "../../../tools/components/modal-overlay/modal-overlay";
import useTable from "../../../tools/hooks/useTable";
import { digitsFormat, getTotalInList } from "../../../tools/libraries/easy";
import PaymentDetailModal from "../dashboard/payment-detail-modal";
import ConfirmationModal from "../../../tools/components/confirmation-modal/confirmation-modal";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

const ReportDetail = () => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showPaymentDetails, setShowPaymentDetails] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const { loading, transactionType, transactionDetails, startDate, endDate } =
    useSelector((state) => state.reports);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTransactionDetails({ startDate, endDate, transactionType }));
  }, [dispatch, startDate, endDate, transactionType]);

  const { range, slice, baseIndex } = useTable(
    transactionDetails,
    page,
    rowsPerPage
  );

  const searchRef = useRef();
  const rowsRef = useRef();

  const handleChange = () => {
    const { value } = searchRef.current;
    if (value !== "") {
      setPage(1);
      rowsRef.current.value = transactionDetails?.length;
      setRowsPerPage(transactionDetails?.length);
    } else {
      rowsRef.current.value = 10;
      setRowsPerPage(10);
    }
    setSearch(value);
  };

  const sendAcknowledgement = () => {
    dispatch(sendTransactionAcknowledgement());
  };

  let status = "text-black";
  if (transactionType === "failed") {
    status = "text-red-500";
  } else if (transactionType === "paid") {
    status = "text-green-500";
  }

  const totalAmount = getTotalInList(transactionDetails, "paymentAmount");

  const csvData = () => {
    let arr = transactionDetails?.map((item, index) => {
      return [
        index + 1,
        item?.billNo,
        item?.description,
        item?.paymentSource,
        item?.paymentDate,
        "GHS " + digitsFormat(item?.paymentAmount),
        item?.egcrNo,
        item?.backOfficeUpdateStatus,
        item?.backOfficeUpdateDate,
        item?.name,
        item?.paymentMode,
      ];
    });
    return arr;
  };
  return (
    <>
      {showConfirmation && (
        <ConfirmationModal
          text={`Are you sure you want to send these ${transactionDetails?.length} unacknowledged records?`}
          handleCancel={() => {
            setShowConfirmation(false);
          }}
          handleConfirm={() => {
            sendAcknowledgement();
            setShowConfirmation(false);
          }}
        />
      )}
      {showPaymentDetails && (
        <ModalOverlay setModalOn={setShowPaymentDetails}>
          <PaymentDetailModal data={selectedData} />
        </ModalOverlay>
      )}
      <div className="bg-white px-5 shadow-md rounded-md">
        <div className="flex flex-col items-between w-full justify-center px-1 sm:px-4 lg:px-10 pt-10 mb-10">
          <div className="w-full">
            <div className="flex justify-between items-center mb-8">
              <h1
                className={`font-bold text-xs lg:text-lg ${status} text-dark`}
              >
                {transactionType?.charAt(0).toUpperCase() +
                  transactionType?.slice(1)}{" "}
                Transactions - {transactionDetails?.length} Records
              </h1>
              <input
                ref={searchRef}
                id="search-field"
                name="search-field"
                className="block w-full sm:max-w-[200px] max-w-[120px] h-full px-3 py-1 text-gray-700 text-md placeholder-gray-500 focus:ring-0 focus:placeholder-gray-400 text-sm md:text-normal border border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                placeholder="Search By Bill Number"
                type="search"
                onChange={handleChange}
              />
            </div>

            <div className="">
              <p className="mr-2">
                Total Amount:{" "}
                <span className={`text-bold font-bold`}>
                  GHS {digitsFormat(totalAmount)}
                </span>
              </p>
              <p className="flex justify-end mt-2 flex-col xs:flex-row sm:mt-0">
                <span className="mr-2 mb-2 xs:mb-0">
                  Start Date: {startDate}
                </span>
                <span>End Date: {endDate}</span>
              </p>
              {transactionDetails && (
                <CSVExport
                  headings={headings.paymentHeadings}
                  csvData={csvData()}
                />
              )}
            </div>
          </div>
          {transactionType === "unacknowledged" && (
            <button
              onClick={() => {
                setShowConfirmation(true);
              }}
              className="w-fit mt-3 bg-lemonGreen hover:bg-green-700 px-2 py-1 text-white rounded-md"
              // disabled={}
            >
              Post To LC
            </button>
          )}
        </div>
        <div className="overflow-x-scroll">
          <table className="overflow-x-scroll table-auto w-full">
            <thead className="text-left">
              <tr className="border-t border-gray-200">
                {headings?.paymentHeadings?.map((item, index) => {
                  return (
                    <th
                      key={index}
                      className="px-3 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      scope="col"
                    >
                      {item}
                    </th>
                  );
                })}
                {/* <th
                  className="px-3 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  scope="col"
                >
                  View
                </th> */}
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="12" className="my-100 border-spacing-y-7">
                    <div className="w-full my-4 bg-opacity-60 z-[900] flex justify-center items-center">
                      <div className="w-16 h-16 animate-spin border-t-2 border-b-2 border-dark rounded-full"></div>
                    </div>
                  </td>
                </tr>
              ) : slice?.length === 0 ? (
                <tr>
                  <td
                    colSpan="9"
                    className="text-center py-10 text-2xl font-sm font-arial-rounded text-gray-600"
                  >
                    No Records Available
                  </td>
                </tr>
              ) : (
                slice
                  ?.filter((item) => {
                    if (search !== "") {
                      return item?.billNo.includes(search);
                    } else {
                      return item;
                    }
                  })
                  .map((item, index) => {
                    return (
                      <tr key={index} className="border">
                        <td className="px-3 py-3 text-sm text-gray-700">
                          {baseIndex + index + 1}
                        </td>
                        <td className="px-3 py-3 text-sm text-gray-700">
                          {item?.billNo}
                        </td>
                        <td className="px-3 py-3 text-sm text-gray-700">
                          {item?.description}
                        </td>
                        <td className="px-3 py-3 text-sm text-gray-700">
                          {item?.paymentSource}
                        </td>
                        <td className="px-3 py-3 text-sm text-gray-700">
                          {item?.paymentDate}
                        </td>
                        <td className="px-3 py-3 text-sm text-gray-700">
                          {digitsFormat(item?.paymentAmount)}
                        </td>
                        <td className="px-3 py-3 text-sm text-gray-700">
                          {item?.egcrNo ? item?.egcrNo : "-"}
                        </td>
                        <td className="px-3 py-3 text-sm text-gray-700">
                          {item?.backOfficeUpdateStatus === "1" ? "Yes" : "No"}
                        </td>
                        <td className="px-3 py-3 text-sm text-gray-700">
                          {item?.backOfficeUpdateDate
                            ? item?.backOfficeUpdateDate
                            : "-"}
                        </td>
                        <td className="px-3 py-3 text-sm text-gray-700">
                          {item?.name}
                        </td>
                        <td className="px-3 py-3 text-sm uppercase text-gray-700">
                          {item?.paymentMode}
                        </td>
                        {/* <td className="px-3 py-3 text-sm text-gray-700">
                          <button
                            onClick={() => {
                              setSelectedData(item);
                              setShowPaymentDetails(true);
                            }}
                            className="bg-lemonGreen px-2 py-1 rounded-md text-white hover:bg-green-700 "
                          >
                            View
                          </button>
                        </td> */}
                      </tr>
                    );
                  })
              )}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan="11" className="text-right justify-end p-3">
                  <div className="flex justify-end items-center">
                    <p className="text-gray-400 text-sm select-none">
                      Rows per page:{" "}
                    </p>
                    <select
                      ref={rowsRef}
                      onChange={(e) => {
                        setPage(1);
                        setRowsPerPage(e.target.value);
                      }}
                      className="ml-2 text-gray-600 outline-none text-sm p-1 select-none"
                    >
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value={transactionDetails?.length}>All</option>
                    </select>
                    <p className="ml-5 text-gray-400 text-sm select-none">
                      {slice?.length ? page : 0} of {range?.length}
                    </p>
                    <div className="ml-5 flex">
                      <FiChevronLeft
                        onClick={() => {
                          if (page > 1) {
                            setPage(page - 1);
                          }
                        }}
                        className="mx-2 w-6 h-6 hover:text-gray-500 rounded-full text-gray-400 cursor-pointer"
                      />
                      <FiChevronRight
                        onClick={() => {
                          if (page < range?.length) {
                            setPage(page + 1);
                          }
                        }}
                        className="mx-2 w-6 h-6 hover:text-gray-500 rounded-full text-gray-400 cursor-pointer"
                      />
                    </div>
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </>
  );
};

export default ReportDetail;
