import React from 'react'

const OverlayLoader = ({message}) => {
  return (
    <div className="fixed min-w-full h-full inset-0 bg-gray-800 bg-opacity-70 flex flex-col justify-center z-[900] items-center">
      <div className="border-white w-20 h-20 rounded-full border-b-2 border-t-2 animate-spin"></div>
      <h1 className="text-white text-lg mt-5">Please wait...</h1>
    </div>
  )
}

export default OverlayLoader;