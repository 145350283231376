import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { END_POINTS } from "../../constants/end-points";
import { URLS } from "../../constants/route-links";
import {
  errorToast,
  successToast,
} from "../../tools/components/toastify/toastify";
import {
  removeItemFromLocalStorage,
  saveObjectInLocalStorage,
} from "../../tools/libraries/easy";
import { request } from "./requests/request";

// Authentication
export const loginUser = createAsyncThunk(
  "company-staff-authenticate",
  async (data, thunkAPI) => {
    try {
      return request(END_POINTS.authenticateCompanyStaff, "POST", data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const verifyStaffActivationLink = createAsyncThunk(
  "verify-activation-link",
  async (data, thunkAPI) => {
    try {
      return request(END_POINTS.verifyStaffActivationLink, "POST", data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const updateCompanyStaffPassword = createAsyncThunk(
  "update-staff-password",
  async (data, thunkAPI) => {
    try {
      return request(END_POINTS.updateCompanyStaffPassword, "PUT", data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const initiatePasswordRecovery = createAsyncThunk(
  "initiate-password-recovery",
  async (data, thunkAPI) => {
    try {
      return request(END_POINTS.initiatePasswordRecovery, "POST", data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const setForgotPassword = createAsyncThunk(
  "set-forgot-password",
  async (data, thunkAPI) => {
    try {
      return request(END_POINTS.setForgotPassword, "PUT", data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const initialState = {
  userInfo: {},
  loading: false,
  errorMessage: "Error connecting to server",
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    getUser: (state, { payload }) => {
      state.userInfo = payload;
    },
    logOut: (state) => {
      removeItemFromLocalStorage("mechanicUser");
      state.userInfo = {};
      state.loading = false;
      state.success = false;
    },
  },
  extraReducers: {
    //Authenticate User
    [loginUser.pending]: (state) => {
      state.loading = true;
    },
    [loginUser.fulfilled]: (state, { payload }) => {
      if (payload.data) {
        successToast(payload.message);
        state.userInfo = payload.data;
        saveObjectInLocalStorage("mechanicUser", payload.data);
        window.location.assign(URLS.dashboard.url);
      } else {
        errorToast("Invalid username or password");
      }
      state.loading = false;
    },
    [loginUser.rejected]: (state) => {
      state.loading = false;
      errorToast(state.errorMessage);
    },

    [initiatePasswordRecovery.pending]: (state) => {
      state.loading = true;
    },
    [initiatePasswordRecovery.fulfilled]: (state, { payload }) => {
      if (payload.data && payload.status === 200) {
        successToast("Password reset OTP sent to your email");
      } else {
        errorToast(payload.message);
      }
      state.loading = false;
    },
    [initiatePasswordRecovery.rejected]: (state) => {
      state.loading = false;
      errorToast(state.errorMessage);
    },
  },
});

export const { logOut, getUser } = userSlice.actions;
export default userSlice.reducer;
